.container {
	min-height: 100vh;
	background-color: rgb(33, 51, 78);
	color: rgb(250, 248, 248);
	display: grid;
	margin: 35px auto;
	grid-template-columns: minmax(350, 600);
	grid-gap: 0;
	justify-content: center;
	align-items: start;
	grid-template-rows: auto auto 1fr;
}

.card {
	width: minmax(320px, 360px);
	background-color: rgb(47, 72, 110);
	justify-content: center;
	padding: 1rem;
	box-shadow: 0 2px 8px 0 rgb(10, 16, 24);
	border-radius: 0.5rem;
	margin: 0.5rem;
}

h3 {
	font-weight: 300;
}

h2 {
	color: rgb(173, 194, 238);
	font-weight: 700;
	line-height: 1.7rem;
	margin-bottom: 5px;
	margin-top: 0.2rem;
}

a {
	text-decoration: none;
	color: #fff;
}

.container h1 {
	color: #fff;
	font-weight: 300;
}

.error {
	color: #fff;
	font-size: 1.5rem;
	margin-top: 30vh;
	justify-content: center;
}

.search {
	border: none;
	background-color: rgb(47, 72, 110);
	color: #fff;
	padding: 1.5rem;
	border-radius: 1.5rem;
	font-size: 1rem;
	height: 2rem;
	width: 90%;
	margin: 0 auto;
}

.search :focus {
	border: none;
	outline: none;
}

.favButton {
	display: flex;
	align-items: center;
	justify-content: space-around;
	border: none;
	background-color: rgb(73, 111, 149);
	color: #fff;
	padding: 1rem;
	border-radius: 0.5rem;
	font-size: 1rem;
	height: .2rem;
	width: 200px;
	margin: 15px auto 0;
	cursor: pointer;
}

.FavoriteIcon {
	color: rgb(224, 244, 251);
}