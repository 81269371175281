.footer{
	color: #fff;
	background-color: #000;
	padding: 10px;
}

.footer img{
	width: 90%;
	max-width: 500px;
	height: auto;
	margin-top: 1rem;
}

.footer h2, h4{
	font-weight: 300;
	color: #fff;
}