* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* .app {
  text-align: center;
  min-height: 100vh;
  display: grid;
  align-items: start;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: grid;
    font-size: calc(10px + 2vmin);
    color: white;
    position: sticky;
  }

  .App-link {
    color: #61dafb;
  }

  .container {
    text-align: center;
    min-height: 90vh;
    display: grid;
    align-items: start;
  }
}