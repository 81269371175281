.header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #04193a;
	margin: 0;
	padding: 20px;
	position: sticky;
	top: 0;
}

h1 {

	color: rgb(144, 175, 226);
	margin: 0;
	line-height: 1.9rem;

}

.icons {

	margin: 10px;
	color: rgb(63, 132, 192);
}

.iconContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	margin: 0 auto;
	color: #fff;
}