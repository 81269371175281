.error {
  color: #fff;
  font-size: 1.5rem;
  margin-top: 30vh;
  justify-content: center;
}

.container {
  background-color: rgb(33, 51, 78);
  min-height: 80vh;
  color: #fff;
  display: grid;
  margin: 30px auto;
  justify-content: center;
  align-content: flex-start;
}

.heading h1 {
  color: #fff;
  font-weight: 600;
}