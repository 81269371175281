.overlay {
  min-height: 100%;
  background-color: rgba(0, 3, 8, 0.2);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}



.modalContent {
  width: 85%;
  max-width: 800px;
  height: 85%;
  margin: 20% auto 0;
  padding: 1rem;
  background-color: rgb(47, 72, 110);
  box-shadow: 0 2px 8px 0 rgb(10, 16, 24);
  border-radius: 0.5rem;
  color: #fff;
}

.modalContent h1 {
  margin-bottom: 1rem;
  margin-bottom: 1rem;
}

.description {
  text-align: left;
  width: 85%;
  margin: 1rem auto;
}

.button {
  font-size: 1rem;
  margin: 1rem auto;
  padding: 0.5rem;
  background-color: rgb(93, 138, 206);
  border-radius: 5px;
  color: #fff;
  border: none;
}

.bigPoster {
  width: 160px;
  height: 236px;
  margin: 0 auto;
}

.modalContent p {
  font-weight: 300;
  line-height: 25px;
}

.error {
  color: #fff;
  font-size: 1.5rem;
  margin-top: 30vh;
  justify-content: center;
}