body {
  background-color: rgb(33, 51, 78);

}

.container {
  background-color: rgb(33, 51, 78);
  min-height: 80vh;
  color: #fff;
  display: grid;
  margin: 0 auto;
  justify-content: center;
  align-content: flex-start;
}

.container h1 {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  color: #fff;
  font-weight: 300;
}

.card h2 {
  color: rgb(173, 194, 238);
  line-height: 1.5rem;
  margin-bottom: 5px;
  margin-top: 2px;
}

.card h4 {
  font-weight: 300;
  line-height: 25px;
  margin-bottom: 10px;
}

.card {
  display: flex;
  min-width: 320px;
  max-width: 500px;
  background-color: rgb(47, 72, 110);
  padding: 1rem;
  box-shadow: 0 2px 8px 0 rgb(10, 16, 24);
  border-radius: 0.5rem;
  margin: 0.5rem;
  text-align: left;
  align-content: center;
}


img {
  height: 100px;
  width: auto;
  margin-right: 1rem;
}

.nomovies {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 2rem;
  color: rgb(250, 248, 248);
}

.summary {
  font-size: .7rem;
  color: #fff;
  background-color: rgb(63, 110, 197);
  padding: 5px;
  border-radius: 2px;
}

.error {
  color: #fff;
  font-size: 1.5rem;
  margin-top: 30vh;
  justify-content: center;
}