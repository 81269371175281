.container {
	background-color: rgb(33, 51, 78);
	min-height: 60vh;
	width: minmax(350, 600);
	color: #fff;
	display: grid;
	margin: 5rem auto;
	justify-content: center;
	align-content: flex-start;
}

.heading {
	margin: 20px auto 20px;
	color: #fff;
}

h2 {
	margin-top: 20px;
	margin-bottom: 0;
}

/* .heading h1 {
	color: #fff;
	font-weight: 600;
} */