.spinner{
	background-color: rgb(33, 51, 78);
	color: #fff;
	display: flex;
  	justify-content: center;
  	align-items: center;
 	height: 100vh;
}

img{
	width: 100px;
	height: 100px;
}