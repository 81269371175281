.container {
	background-color: rgb(33, 51, 78);
	min-height: 60vh;
	width: minmax(350, 600);
	color: #fff;
	display: grid;
	margin: 5rem auto;
	justify-content: center;
	align-content: flex-start;
}

.deleteIcon {
	color: rgb(224, 244, 251);
}

.deleteButton {
	display: flex;
	align-items: center;
	justify-content: space-around;
	border: none;
	background-color: rgb(73, 111, 149);
	color: #fff;
	padding: 1rem;
	border-radius: 0.5rem;
	font-size: 1rem;
	height: .2rem;
	width: 200px;
	margin: 15px auto 30px;
	cursor: pointer;
}

.heading1 h1 {
	color: #fff;
	font-weight: 600;
}

.message {
	color: #fff;
	font-weight: 400;
	font-size: 1.5rem;
	margin: 0 auto;
	text-align: center;
	margin-top: 20vh;
	min-height: 60vh;
}